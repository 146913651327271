import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Usuarios } from '../models/usuarios.model';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { PerfilPago } from '../models/perfil-pago';
import { PerfilServicios } from '../models/perfil-servicios';

interface UsuariosData {
  // personaId: number,
  // nombre1: string,
  // nombre2: string,
  // apellido1: string,
  // apellido2: string,
  // email: string,
  // telefono: string,
  // fechaNacimiento: string,
  // nit: string,
  // rutaImagen: string
  persona: string
}

const USER = 'user';
const USERTYPE = 'tipo';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  baseUrl = 'https://api.swigit.gt/Usuario';
  // baseUrl = 'https://jsonplaceholder.typicode.com/users';

  //private _usuarios = new BehaviorSubject<Usuarios[]>([]);
  authenticationState = new BehaviorSubject(false);
  activeUser = new BehaviorSubject({user:'',empresa:'',tipo:0,img:''});
  //userType = new BehaviorSubject(0);
  public usuario: any;

 
  constructor( private http: HttpClient, private storage: Storage, private platform: Platform) { 
    /*this.platform.ready().then(()=>{
      this.checkToken();
    });*/
  }

  /*checkToken(){
    this.storage.get(USER).then(res => {
      if(res)
        this.authenticationState.next(true);
    });
  }*/

  getUsuarios(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    return this.http.get('https://api.swigit.gt/Usuario', {headers: headers});
  }

  getUsuario(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get(`${this.baseUrl}/${idusuario}`, {headers: headers});
     
  }

  getUsuarioId(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Usuarios>(`${this.baseUrl}/${idusuario}`, {headers: headers});
     
  }

  getUsuarioLogin(usuarioLogin)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
 

    /*this.activeUser.next({user:usuarioLogin.NombreUsuario,empresa:'',tipo:0,img:''});  
    this.storage.set(USER,{user:usuarioLogin.NombreUsuario,empresa:'',tipo:0,img:''}).then(res => {
        this.authenticationState.next(true);
      });*/

    return this.http.post('https://api.swigit.gt/Usuario/Login', usuarioLogin, {headers: headers});
     
  }



  isAuthenticated(){
    return this.authenticationState.value;
  }

  /*getUsuarioActivo(){ 
    return this.storage.get(USER).then(res => {
      console.log('back service: ' + res);
    });
  }
  getUsuarioTipo(){ 
    return this.storage.get(USERTYPE).then(res => {
      console.log('back service tipo: ' + res);
    });
  }
*/
  setUserInfo(user){
    return this.storage.set(USER,user).then(res => {
      //this.userType.next(tipo);
      this.activeUser.next(user);
      this.authenticationState.next(true);
    });
  }

  refresh(){
    this.storage.get(USER).then(res => {
      if(res){
        //console.log(JSON.stringify(res));
        this.activeUser.next(res);
        this.authenticationState.next(true);
      }else{
        this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
        this.authenticationState.next(false);
      }
      
    });
    /*this.storage.get(USERTYPE).then(res => {
      console.log(JSON.stringify(res));
      this.userType.next(res);
    });*/
  }

  userLogout(){
    this.storage.remove(USER).then(res => {
      this.authenticationState.next(false);
      this.activeUser.next({user:'',empresa:'',tipo:0,img:''});
    });
  }

  postUsuario(usuario)
  {
  //  console.log(usuario);
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return this.http.post('https://api.swigit.gt/Usuario/CrearUsuario', usuario, { headers: headers });
  }

  putUsuario(usuario)
  {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.put('https://api.swigit.gt/Usuario/ModificarUsuario', usuario, { headers: headers }).subscribe();
  }

  putContrasenia(recuperacionContrasenia)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': recuperacionContrasenia.token
      });
    this.http.put('https://api.swigit.gt/Usuario/NuevaContrasenia', recuperacionContrasenia, { headers: headers }).subscribe();
  }

  reestablecerContrasenia(usuario)
  {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.http.put('https://api.swigit.gt/Usuario/ModificarUsuario', usuario, { headers: headers }).subscribe();
  }

  putTokenPush(usuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    //  console.log("llego token push");
    //  console.log(localStorage.getItem("token"));
    //this.http.post('https://api.swigit.gt/Usuario/TokenPush', usuario, { headers: headers }).subscribe();
    this.http.put('https://api.swigit.gt/Usuario/TokenPush', usuario, { headers: headers }).subscribe();

  }

  correoRecuperacion(recuperacionContrasenia)
  {
    //console.log(recuperacionContrasenia);
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.http.post('https://api.swigit.gt/Usuario/RecuperarContrasenia', recuperacionContrasenia, { headers: headers }).subscribe();
  }

  postFrase(frase)
  {
    //console.log(frase);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Usuario/AgregarFrase', frase, { headers: headers }).subscribe();
  }

  postEstablecimiento(establecimiento)
  {
    //console.log(establecimiento);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Usuario/AgregarEstablecimiento', establecimiento, { headers: headers }).subscribe();
  }

  eliminarFrase(frase)
  {
    //console.log(frase);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Usuario/EliminarFrase', frase, { headers: headers }).subscribe();
  }

  
  eliminarEstablecimiento(establecimiento)
  {
    //console.log(establecimiento);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Usuario/EliminarEstablecimiento', establecimiento, { headers: headers }).subscribe();
  }

  getPerfilPago(personaId)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<PerfilPago>("https://epayapi.swigit.gt/perfilPago/" + personaId, {headers: headers});
  }

  getPerfilPagoToken(token)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': token
      });
    return this.http.get<PerfilPago>("https://epayapi.swigit.gt/Token", {headers: headers});
  }

  postPerfilPago(perfipagoObj: PerfilPago)
  {
    //  console.log(usuario);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });


    return this.http.post('https://epayapi.swigit.gt/perfilPago', perfipagoObj, { headers: headers });
  }

  getPerfilServicios()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

        console.log(localStorage.getItem("token"));
    return this.http.get<PerfilServicios>("https://epayapi.swigit.gt/PerfilPago/servicios/" , {headers: headers});
  }

  
  getPerfilServiciosPublic(token)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': token
      });

    return this.http.get<PerfilServicios>("https://epayapi.swigit.gt/PerfilPago/servicios/" , {headers: headers});
  }
 

  
}
