import { Component, OnInit, Input } from '@angular/core';
import { FELObj } from '../../models/fel/felobj';
import { resultado_certificacion } from '../../models/fel/resultado_certificacion';
import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WordPressService } from 'src/app/services/word-press.service';
import { SharingService } from 'src/app/services/sharing.service';
import { Producto } from 'src/app/models/producto';


@Component({
  selector: 'app-pop-orden-simple',
  templateUrl: './pop-orden-simple.component.html',
  styleUrls: ['./pop-orden-simple.component.scss'],
})
export class PopOrdenSimpleComponent implements OnInit {


  @Input() ordenId: string;
  FechaResumen: Date;
  isNative: Boolean;
  personaId: string;
  urlPOSWC: string;
  consumerKey: string;
  consumerSecret: string;
  usuario: any;

  constructor(private printer: Printer,
    private file: File,
    private nativeHTTP: HTTP,
    private fileOpener: FileOpener,
    private ft: FileTransfer,
    public usuarioService: UsuarioService,
    private loadingService: LoadingService,
    public wordPressService: WordPressService,
    private sharingService: SharingService) {   }

  ngOnInit() {
    this.FechaResumen = new Date();

    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
  }

  ionViewDidEnter(){
    this.FechaResumen = new Date();
    this.credencialesWP();
  }


  verPedido()
  {
    let verPedidoUrl = "https://" + this.urlPOSWC + "/wp-admin/post.php?post=" + this.ordenId + "&action=edit";
    window.open(verPedidoUrl);
  }

  credencialesWP()
  {
    this.personaId = localStorage.getItem("personaId");
    this.usuario = this.usuarioService.getUsuario(this.personaId).subscribe(data =>
      {
        this.usuario = data;
        let tieneLlavesWC: boolean = this.usuario.urlPOSWC == null ? false: true;
            if(tieneLlavesWC == true)
            {
              this.urlPOSWC = this.usuario.urlPOSWC;
              this.consumerKey = this.usuario.consumerKey;
              this.consumerSecret = this.usuario.consumerSecret;
            }
      },
      error => {
        console.log(error);
       
      });
  }

  cargarProductosWC(url, consumer_key, consumer_secret)
  {
    this.loadingService.present();
    this.usuario = this.wordPressService.getProductosWC(url, consumer_key, consumer_secret).subscribe(data =>
      {
        console.log("LLEGO DATA WOOCOMMERCE");
        console.log(data);
        let listaProducto : Producto[] = [];
        
        let listaWC = data as any;

        listaWC.forEach(function (item, index) {
          let productoItem = new Producto(0, "", "", "", 0, "", 0, false, false, "" );
          productoItem.nombre = item.name;
          productoItem.descripcion = "";
          productoItem.inventario = item.stock_quantity;
          productoItem.esServicio = false;
          productoItem.id = item.id;
          productoItem.precio = (Number)(item.price);
          productoItem.sku = item.sku;
          productoItem.urlImagen = item.images[0].src;
          productoItem.codigoBarras = item.meta_data.find(meta => meta.key == "qr_swigit") != null ? item.meta_data.find(meta => meta.key == "qr_swigit").value : ""; // meta_data -> recorrer buscando un ID o llave que creemos nosotros "qrswigit" -> en el value guardar los códigos de barra
          productoItem.variable = item.variations[0] != null ? true : false;
          listaProducto.push(productoItem);
       
        }
        );

        console.log("PRODUCTOS PRE CARGADOS EN POP ORDEN");
        console.log(listaProducto);
        this.sharingService.setProductoInfo(listaProducto);
     
        
        this.loadingService.dismiss();
        
        
       
      }
    );
  }

  ngOnDestroy()
  {
    console.log("LLEGO ONDESTROY POP ORDEN");
    this.cargarProductosWC(this.urlPOSWC, this.consumerKey, this.consumerSecret);
  }




}
