import { GTDocumento } from './gtdocumento';
import { Emisor } from './emisor';

export class FELObj {
    public clientkey: string;
    public GTDocumento: GTDocumento;

    // usuario = "DIRISA111";
    // llave_certificacion="36112C0EDC0D55CC9ACFAF7A34D39886";
    // llave_firma="f4e695bd854acf5fe2ed642d67dfb461";
    // alias="DIRISA";

    public usuario: string;
    public llave_certificacion: string;
    public llave_firma: string;
    public alias: string;
    
    // public codigo :string;
    // public identificador_unico:string;
    // es_anulacion="N";
    // correo_copia="josue-d-1@hotmail.com";
    // nit_emisor="100888704";

    public codigo :string;
    public identificador_unico:string;
    es_anulacion="N";
    public correo_copia: string;
    public nit_emisor: string;
    public origenEmision: number;
    // public nombreEmisor: string;
    // public codigoEstablecimiento: string; 
    // public nombreComercial: string; 
    // public correoEmisor: string;
    // public afiliacionIVA: string;

    
    constructor(key:string,nit:string, nombre:string, correo:string,
        direccion: string, usuario: string, llave_certificacion: string, llave_firma: string, alias: string, correo_copia:string, nit_emisor: string, 
        nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string ){
        this.clientkey = key;
        this.usuario = usuario;
        this.llave_certificacion = llave_certificacion;
        this.llave_firma = llave_firma;
        this.alias = alias;
        this.correo_copia = correo_copia;
        this.nit_emisor = nit_emisor;
        this.origenEmision = 1;
        // this.nombreEmisor = nombreEmisor;
        // this.codigoEstablecimiento = codigoEstablecimiento;
        // this.nombreComercial = nombreComercial;
        // this.correoEmisor = correoEmisor;
        // this.afiliacionIVA = afiliacionIVA;
        this.GTDocumento = new GTDocumento(nit,nombre,correo,direccion, nit_emisor, nombreEmisor, codigoEstablecimiento, nombreComercial, correoEmisor, afiliacionIVA);
    }

    agregarItems(Items: any){
        this.GTDocumento.SAT.DTE.DatosEmision.agregarProductos(Items);
    }

    agregarEmisor(){
        // this.GTDocumento.SAT.DTE.DatosEmision.Emisor = new Emisor(this.nit_emisor, this.nombreEmisor, this.codigoEstablecimiento, this.nombreComercial, this.correoEmisor, this.afiliacionIVA);
        // this.GTDocumento.SAT.DTE.DatosEmision.Emisor
    }
}
