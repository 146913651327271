import { Component, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventEmitter } from 'events';
import { Carrito } from 'src/app/models/carrito';
import { LoadingService } from 'src/app/services/loading.service';
import { SharingService } from 'src/app/services/sharing.service';
import { ClientesComponent } from '../clientes/clientes.component';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {

 
  arregloCarrito: Carrito[];
  total: number = 0;
  @Output() emiterCarrito = new EventEmitter();

  constructor(public modalCtrl: ModalController,
    private sharingService: SharingService,
    public loadingService: LoadingService) { }

  ngOnInit() {
   
  }

  ionViewDidEnter()
  {
    this.arregloCarrito = this.sharingService.sharingCart;
    this.precargarTotal();
    this.sharingService.sharingCarritoObservable.subscribe((_carrito)=>{
      this.arregloCarrito = _carrito;
    });

  }

  precargarTotal()
  {
    for(let item of this.arregloCarrito)
    {
      this.actualizarTotalInicio(item);
    } 
  }

  eliminar(item)
  {
    this.actualizarTotalEliminar(item);
    let i = this.arregloCarrito.indexOf(item);
    this.arregloCarrito.splice(i,1);
    this.sharingService.setCarritoInfo(this.arregloCarrito);

  }

  sumarCantidad(item)
  {
    item.cantidad = item.cantidad + 1;
    this.actualizarTotal(item, true);
    this.sharingService.setCarritoInfo(this.arregloCarrito);
  }

  restarCantidad(item)
  {
    if(item.cantidad != 1)
    {
      item.cantidad = item.cantidad - 1;
      this.actualizarTotal(item, false);
      this.sharingService.setCarritoInfo(this.arregloCarrito);
    }

  }

  actualizarTotal(item: Carrito, suma: boolean)
  {
    if(suma == true)
      this.total = this.total + item.precio;
    else
      this.total = this.total - item.precio;
  }

  actualizarTotalInicio(item: Carrito)
  {
      this.total = this.total + item.precio*item.cantidad;
  }

  actualizarTotalEliminar(item: Carrito)
  {
      this.total = this.total - item.precio*item.cantidad;
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  async finalizarCarrito()
  {
    this.modalCtrl.dismiss();
    console.log("carrito final: ", this.arregloCarrito);
    const modal = await this.modalCtrl.create({
      component: ClientesComponent,
      cssClass: 'my-custom-class'
    });
    await modal.present();
  }


}
