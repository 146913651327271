export class TotalImpuestos {

    public NombreCorto: string;
    public TotalMontoImpuesto: number;

    constructor(nombre: string, total:number){
        this.NombreCorto = nombre;
        this.TotalMontoImpuesto = total;
    }

}
