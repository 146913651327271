export class anulacionbj {
    public usuario: string;
    public llave_certificacion: string;
    public llave_firma: string;
    public alias: string;

    public codigo :string;
    public identificador_unico:string;
    public es_anulacion="S";
    public correo_copia: string;
    public nit_emisor: string;

    public FechaEmisionDocumentoAnular: string;
    public FechaAnulacion: string;
    public IDReceptor: string;
    public MotivoAnulacion:string;
    public NumeroDocumentoAAnular:string;
    public clientKey:string;
    

    
    constructor(usuario: string, llave_certificacion: string,
                llave_firma: string, alias: string, correo_copia:string, 
                nit_emisor: string, fechaemision: string, nitreceptor: string,
                motivo:string, uuidanular: string, clientKey: string){
        this.usuario = usuario;
        this.llave_certificacion = llave_certificacion;
        this.llave_firma = llave_firma;
        this.alias = alias;
        this.correo_copia = correo_copia;
        this.nit_emisor = nit_emisor;
        this.FechaEmisionDocumentoAnular = fechaemision;
        this.IDReceptor = nitreceptor;
        this.MotivoAnulacion = motivo;
        this.NumeroDocumentoAAnular = uuidanular;
        this.clientKey = clientKey;
        this.codigo = "1";
        this.FechaAnulacion =  fechaemision;
    }
}
