import { SAT } from './sat';

export class GTDocumento {
    
    public Version: string;
    public SAT: SAT;
    
    constructor(nit:string, nombre:string, correo:string,
        direccion: string, nit_emisor: string, 
        nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string){
        this.Version = "0.4";
        this.SAT = new SAT(nit,nombre,correo,direccion, nit_emisor, nombreEmisor, codigoEstablecimiento, nombreComercial, correoEmisor, afiliacionIVA);
    }
}
