import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { AlertController, ToastController, PopoverController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Clientes } from 'src/app/models/clientes.model';
import { PopFacturadorComponent } from '../pop-facturador/pop-facturador.component';
import { PopFacturaErrorComponent } from '../pop-factura-error/pop-factura-error.component';
import { resultado_certificacion } from 'src/app/models/fel/resultado_certificacion';
import { Item } from 'src/app/models/fel/item';
import { FelService } from 'src/app/services/fel.service';
import { FELObj } from 'src/app/models/fel/felobj';
import { LoadingService } from 'src/app/services/loading.service';
import { Carrito } from 'src/app/models/carrito';
import { SharingService } from 'src/app/services/sharing.service';
import { ClientesComponent } from '../clientes/clientes.component';
import { NgForm } from '@angular/forms';
import { PopOrdenComponent } from '../pop-orden/pop-orden.component';
import { WordPressService } from 'src/app/services/word-press.service';
import { NombreNIT } from 'src/app/models/nombre-nit';
import { NombreNitRespuesta } from 'src/app/models/nombre-nit-respuesta';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PopOrdenSimpleComponent } from '../pop-orden-simple/pop-orden-simple.component';

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.scss'],
})
export class NuevoClienteComponent implements OnInit {

  cliente = {
    usuario : 
    {
        personaId: parseInt(localStorage.getItem("personaId")),
        nombre1:null,
        nombre2:null,
        apellido1:null,
        apellido2:null,
        email:null,
        telefono:null,
        fechaNacimiento:"1995-03-11T12:45:36",
        nit:null,
        rutaImagen:null,
        razonSocial: null,
        direccion: null
       
    },
    cliente :
    {
        personaId: -1,
        nombre1:"",
        nombre2:null,
        apellido1:"",
        apellido2:null,
        email:null,
        telefono:"",
        fechaNacimiento:"1995-03-11T12:45:36",
        nit:"",
        rutaImagen:null,
        razonSocial: null,
        direccion: "Ciudad"
    }
  };
  
  personaIdCreada: any;
  arregloCarrito: Carrito[];
  total: number = 0;
  nitObj = new NombreNIT("100888704");
  nombreNitRespuesta = new NombreNitRespuesta("", 0, "" , "");
  usuario: any;
  personaId: string;
  url: string;
  consumer_key: string;
  consumer_secret: string;
  activarFacturacion:boolean = true;
  ordenCompletada:boolean = true;
  
    constructor(private clienteService: ClienteService,
                private alertController: AlertController,
                private toastCtrl: ToastController,
                private router: Router,
                private popCtrl: PopoverController,
                private felService: FelService,
                private modalCtrl: ModalController,
                private loading: LoadingService,
                private sharingService: SharingService,
                private wordPressService: WordPressService,
                public usuarioService: UsuarioService) { }
  
    ngOnInit() {
    }

    ionViewDidEnter()
    {
      this.arregloCarrito = this.sharingService.sharingCart;
      this.precargarTotal();
      this.sharingService.sharingCarritoObservable.subscribe((_carrito)=>{
        this.arregloCarrito = _carrito;
      });

      this.cargarDatosUsr();

    }

    cargarDatosUsr()
    {
      this.personaId = localStorage.getItem("personaId");
      this.usuario = this.usuarioService.getUsuario(this.personaId).subscribe(data =>
        {  
          this.usuario = data;
          this.url = this.usuario.urlPOSWC; //"agdesign.com.gt";
          this.consumer_key = this.usuario.consumerKey; //"ck_2c1391e03f828ab74df3d68ff04e169dfbb32d98";
          this.consumer_secret = this.usuario.consumerSecret; //"cs_585012f85ef10df5077c3ca7b8a68dc18a63c3e3";


        },
        error => {
          console.log(error);
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        });
    }

    precargarTotal()
    {
      for(let item of this.arregloCarrito)
      {
        this.actualizarTotalInicio(item);
      } 
    }
  
    actualizarTotalInicio(item: Carrito)
    {
        this.total = this.total + item.precio*item.cantidad;
    }

    async presentAlertConfirm() {
      const alert = await this.alertController.create({
        header: 'Cliente creado',
        message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Deseas generar una factura?</p>',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              //no desea crear factura
              this.router.navigate(['/cliente']);
            }
          }, {
            text: 'Aceptar',
            handler: () => {
                //si desea crear factura
                this.router.navigate(['/facturador/' + this.personaIdCreada]);
            }
          }
        ]
      });
  
      await alert.present();
    }
  
  
    registrarFacturar(form: NgForm){


      if(!form.valid)
      {
        //no chequeo terminos
        this.presentToast('Verifica que hayas completado todos los datos requeridos.');
        return;
      }


      this.clienteService.postCliente(this.cliente).subscribe(data =>
      {

        this.personaIdCreada = data;
        console.log("id creada: ", this.personaIdCreada);
        this.crearOrdenWC();

      },
      error => {
        console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });


    }
    async presentToast(mensaje: string) {
      const toast = await this.toastCtrl.create({
        message: mensaje,
        duration: 2000
      });
      toast.present();
    }



    crearOrdenWC()
    {
      this.loading.present();
      var itemsLine = [];
      for(let xproducto of this.arregloCarrito)
      {
        var item = 
        {
          product_id: xproducto.id,
          quantity: xproducto.cantidad
        }
        itemsLine.push(item);
      }
  
      let jsonOrden = {
        payment_method: "Tienda",
        status:  this.ordenCompletada == true ? "completed" : "processing",
        billing:
          {
            first_name:this.cliente.cliente.nombre1,
            email: this.cliente.cliente.email,
            address_1: "Ciudad"
          },
        line_items: itemsLine,
        meta_data:
            [{
              key:"billing_nit",
              value: this.cliente.cliente.nit,
            },
            {
              key:"billing_nitnombre",
              value: this.cliente.cliente.razonSocial,
            },
            {
              key:"nit_client",
              value: this.cliente.cliente.nit,
            },
            {
              key:"error-fel",
              value: "",
            },
            {
              key:"is_vat_exempt",
              value: "no",
            },
            {
              key:"meta-fel",
              value: "0",
            },
            ]
          
             
        };
  
  
      this.wordPressService.postOrdenWC(this.url, this.consumer_key, this.consumer_secret, jsonOrden).subscribe(data =>
        {
          console.log("DATA ORDEN CREADA: " + data);
          let ordenCreada: any = data;
          if(this.activarFacturacion == true)
            this.facturar(ordenCreada);
          else
          {
            this.loading.dismiss();
            this.modalCtrl.dismiss();
            this.mostrarPopOrdenSimple(ordenCreada.id.toString());
          }
        }
      );
    }

    
  facturar(ordenCreada)
  {

    console.log("llego facturar");
      let doc = new FELObj(localStorage.getItem("token"),this.cliente.cliente.nit, this.cliente.cliente.razonSocial,
      this.cliente.cliente.email, this.cliente.cliente.direccion, "", "", "",
      "", "", "", "", this.sharingService.sharingValue.split("-")[0], this.sharingService.sharingValue.split("-")[1], "","");

    var itemsAux = [];
    for(let xproducto of this.arregloCarrito)
    {
      var item = new Item(xproducto.nombre,
      "",xproducto.precio, xproducto.cantidad,0,xproducto.esServicio == true ? "S" : "B","UN");      
      itemsAux.push(item);
      
    }

    doc.agregarItems(itemsAux);

    this.felService.facturar(doc).subscribe(data=>{

  
      this.loading.dismiss();
      this.modalCtrl.dismiss();
       //actualizar meta datos de pedido
       this.actualizarPedido(ordenCreada, data);
      this.mostrarPopOrden(data, ordenCreada.id.toString());
    
      
    },
    error =>
    {
    }
    );

  }

  
  actualizarPedido(ordenCreada, factura)
  {

    let objResultado: resultado_certificacion = JSON.parse(factura); //detalle; 

    let arregloMeta = {
      meta_data:
        [{
            key:"meta-fel",
            value:"1",
          },
          {
            key:"uuid",
            value:objResultado.uuid,
          }
        ]
      };

      console.log("ORDEN CREADA: ");
      console.log(ordenCreada);
      console.log(arregloMeta);

      this.wordPressService.putOrdenWC(this.url, this.consumer_key
         ,this.consumer_secret, arregloMeta, ordenCreada.id);
   
  }

  async mostrarPopOrdenSimple(ordenId) {

    this.limpiarCarrito();
    const popover = await this.popCtrl.create({
      component: PopOrdenSimpleComponent,
      componentProps: { 
                      ordenId: ordenId
                    },
      event: ordenId
    });
    return await popover.present();
  }

  async mostrarPopOrden(detalle, ordenId) {

    let objResultado: resultado_certificacion = JSON.parse(detalle); //detalle; 

    if(objResultado.resultado == "Exito")
    {
      this.limpiarCarrito();
        const popover = await this.popCtrl.create({
          component: PopOrdenComponent,
          componentProps: { resultado : objResultado,
                          ordenId: ordenId
                        },
          event: detalle
        });
        return await popover.present();
    }
    else
    {
      const popover = await this.popCtrl.create({
        component: PopFacturaErrorComponent,
        componentProps: { resultado : objResultado},
        event: detalle
      });
      return await popover.present();

    }
  }

  async limpiarCarrito()
  {
    console.log("llego limpiar carrito");
    this.arregloCarrito = [];
    console.log("carrito limpio: ", this.arregloCarrito);
    this.sharingService.setCarritoInfo(this.arregloCarrito);
  }

  async mostrarPop(detalle) {

    let objResultado: resultado_certificacion = JSON.parse(detalle);

    if(objResultado.resultado == "Exito")
    {
        this.limpiarCarrito();
        const popover = await this.popCtrl.create({
          component: PopFacturadorComponent,
          componentProps: { resultado : objResultado},
          event: detalle
        });
        return await popover.present();
    }
    else
    {
      const popover = await this.popCtrl.create({
        component: PopFacturaErrorComponent,
        componentProps: { resultado : objResultado},
        event: detalle
      });
      return await popover.present();

    }
  }

  async salirModal()
  {
    this.modalCtrl.dismiss();
    console.log("carrito final: ", this.arregloCarrito);
    const modal = await this.modalCtrl.create({
      component: ClientesComponent,
      cssClass: 'my-custom-class'
    });
    await modal.present();
  }


  buscar(event)
  {
    let nit = event.detail.value;
    this.nitObj.nitConsulta = nit.replace("-", "").toUpperCase();
    console.log(this.nitObj);

    if(this.nitObj.nitConsulta.length >= 4)
    {

    this.felService.getNombreNIT(this.nitObj).subscribe(data =>
       {
        console.log("respuesta infile: " + data);
        this.nombreNitRespuesta = data;
        this.cliente.cliente.razonSocial = data.nombre;
        this.cliente.cliente.nit = this.nitObj.nitConsulta;
        this.cliente.cliente.nombre1 = data.nombre;
       });

    }
    
  }

}
