export class Direccion {
    public Direccion: string;
    public CodigoPostal: string;
    public Municipio: string;
    public Departamento: string;
    public Pais: string;

    constructor(direccion:string){
        this.Direccion = direccion;
        this.CodigoPostal = "10001";
        this.Municipio = "Guatemala";
        this.Departamento = "Guatemala";
        this.Pais = "GT";
    }
}
