import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from 'src/app/models/link';
import { AuthorizationRequestWithClientToken } from '../models/authorization-request-with-client-token';
import { Epay } from '../models/epay';
import { EpayAnulacion } from '../models/epay-anulacion';
import { EpayResponse } from '../models/epay-response';
import { FiltroTransacciones } from '../models/filtro-transacciones';
import { ItemReporte } from '../models/item-reporte';
import { ResumenTransaccion } from '../models/resumen-transaccion';
import { TokenTarjeta } from '../models/token-tarjeta';
import { Transaccion } from '../models/transaccion';

@Injectable({
  providedIn: 'root'
})
export class EpayService {

  baseUrl = 'https://api.swigit.gt/';
  constructor(private http: HttpClient) { }


  postLink(linkObj : Link)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(linkObj);
    return this.http.post<Link>("https://epayapi.swigit.gt/linkPago", linkObj, { headers: headers });
    
  }

  putLink(linkObj : Link)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(linkObj);
    return this.http.put<Link>("https://epayapi.swigit.gt/linkPago/" + linkObj.linkPagoToken , linkObj, { headers: headers });
    
  }

  deleteLink(linkObj : Link)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

     console.log(linkObj);
    return this.http.delete<Link>("https://epayapi.swigit.gt/linkPago/" + linkObj.linkPagoToken , { headers: headers });
    
  }

  getLinks()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

     return this.http.get<Link>("https://epayapi.swigit.gt/linkPago/", { headers: headers });
     
  }

  getTokens()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

     return this.http.get<TokenTarjeta>("https://epayapi.swigit.gt/CSToken", { headers: headers });
     
  }

  getLink(tokenPago)
  {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });
    return this.http.get<Link>("https://epayapi.swigit.gt/linkPago/" + tokenPago, { headers: headers });
     
  }



  requestEpay(epayObj: Epay)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(epayObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/venta", epayObj, { headers: headers });
  }

  requestEpayLink(epayObj: Epay, token)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': token
      });

    // console.log(epayObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/venta", epayObj, { headers: headers });
  }


  
  requestRecurrencia(epayObj: Epay, tokenCliente)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token':  tokenCliente
      });

    // console.log(epayObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/venta", epayObj, { headers: headers });
  }

  tokeniza(epayObj: Epay, token)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': token
      });

    // console.log(epayObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/Venta/Tokeniza", epayObj, { headers: headers });
  }

  
  requestCyberToken(requestTokenObj: AuthorizationRequestWithClientToken)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(epayObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/CSToken", requestTokenObj, { headers: headers });
  }
  
  requestEpayAnular(epayAnulaObj: EpayAnulacion)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(epayAnulaObj);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/anulacion", epayAnulaObj, { headers: headers });
  }

  putTransaccion(transaccion: Transaccion)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(epayAnulaObj);
    return this.http.put<Transaccion>("https://epayapi.swigit.gt/Transacciones/" + transaccion.transaccionId, transaccion, { headers: headers });
  }

  getIPAddress()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  transaction(epayObj)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

    // console.log(epayObj);
    return this.http.post("http://epayapi.swigit.gt/venta", epayObj, { headers: headers });
  }

  getTransaction(transactionid)
  {
    //console.log(idcliente);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Transaccion>(`${this.baseUrl}epay/${transactionid}`, { headers: headers });
  }

  getTransactionAuthorization(auditoria)
  {
    //console.log(idcliente);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<Transaccion>("https://epayapi.swigit.gt/Transacciones/" + auditoria, { headers: headers });
  }

  getTransactions(filtroFecha: FiltroTransacciones)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

      // console.log(localStorage.getItem("token"));

     return this.http.post<Observable<Transaccion>>('https://epayapi.swigit.gt/Transacciones', filtroFecha , { headers: headers });
  }

  requestEpayPaquete(epayObj: Epay, puntos, tiempo, servicio, paqueteID)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token"),
      'puntos': puntos.toString(),
      'tiempo': tiempo.toString(),
      'servicio': servicio.toString(),
      'paquete': paqueteID.toString()
      });

      // console.log(epayObj);
      // console.log(puntos);
    return this.http.post<EpayResponse>("https://epayapi.swigit.gt/venta/paquete", epayObj, { headers: headers });
  }

  getResumenTransacciones()
  {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<ResumenTransaccion>("https://epayapi.swigit.gt/Transacciones/resumen", { headers: headers });
     
  }

  getResumenTransaccionesDolares()
  {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get<ResumenTransaccion>("https://epayapi.swigit.gt/Transacciones/resumen/resumenUSD", { headers: headers });
     
  }

  getGraficaAnual()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
      return this.http.get<ItemReporte[]>("https://epayapi.swigit.gt/Estadisticas/EpayAnual", { headers: headers });
  }


  getGraficaAnualFel()
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
      return this.http.get<ItemReporte[]>("https://epayapi.swigit.gt/Estadisticas/FelAnual", { headers: headers });
  }

}
