import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule} from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { PopOpcionesUsuariosComponent } from './components/pop-opciones-usuarios/pop-opciones-usuarios.component';
import { PopFacturasComponent } from './components/pop-facturas/pop-facturas.component';
import { PopClienteComponent } from './components/pop-cliente/pop-cliente.component';
import { PopFacturadorComponent } from './components/pop-facturador/pop-facturador.component';

//FCM
// import { FCM } from '@ionic-native/fcm/ngx';

//Local Notifications
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

//FILE
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';

//IMAGE PICKER
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { PopFacturaErrorComponent } from './components/pop-factura-error/pop-factura-error.component';
import { OpcionesLinkComponent } from './components/opciones-link/opciones-link.component';
import { PopTransaccionesComponent } from './components/pop-transacciones/pop-transacciones.component';

//card IO
import { CardIO } from '@ionic-native/card-io/ngx';
import { CarritoComponent } from './components/carrito/carrito.component';
import { PopPaqueteComponent } from './components/pop-paquete/pop-paquete.component';
import { NuevoProductoComponent } from './components/nuevo-producto/nuevo-producto.component';


import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import { ClientesComponent } from './components/clientes/clientes.component';
import { NuevoClienteComponent } from './components/nuevo-cliente/nuevo-cliente.component';
import { PopOrdenComponent } from './components/pop-orden/pop-orden.component';

import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { PopAnuncioComponent } from './components/pop-anuncio/pop-anuncio.component';
import { PopOrdenSimpleComponent } from './components/pop-orden-simple/pop-orden-simple.component';
import { NFC } from '@ionic-native/nfc/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [ PopOpcionesUsuariosComponent,
     PopFacturasComponent,
     PopClienteComponent,  
     PopFacturadorComponent,
     PopFacturaErrorComponent,
     OpcionesLinkComponent,
     PopTransaccionesComponent,
     CarritoComponent,
     PopPaqueteComponent,
     NuevoProductoComponent,
     ClientesComponent,
     NuevoClienteComponent,
     PopOrdenComponent,
     PopAnuncioComponent,
     PopOrdenSimpleComponent
     
    ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ComponentsModule, PipesModule, IonicStorageModule.forRoot()],
  providers: [
    StatusBar,
    FileChooser,
    IOSFilePicker,
    CallNumber,
    SplashScreen,
    StreamingMedia,
    // FCM,
    File,
    FileTransfer,
    LocalNotifications,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    FileOpener,
    FileTransfer,
    ImagePicker,
    DocumentViewer,
    CardIO,
    BarcodeScanner,
    Printer,
    HTTP,
    NFC
    
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
