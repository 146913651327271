import { Component, OnInit } from '@angular/core';
import { ToastController, PopoverController, AlertController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-pop-cliente',
  templateUrl: './pop-cliente.component.html',
  styleUrls: ['./pop-cliente.component.scss'],
})
export class PopClienteComponent implements OnInit {
/*  Validacion de plataforma nativa  */
  isNative: Boolean;
  objeto: any;

  constructor(private callNumber: CallNumber,
    private popCtrl: PopoverController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private clienteService: ClienteService,
    private router: Router) { }

  ngOnInit() {
    /* Verifica si la plataforma de nativa  */
    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
    //console.log('isNative : ',this.isNative);
  }

  llamar(numero)
  {
    this.popCtrl.dismiss();
     this.callNumber.callNumber(numero, true)
       .then(res => console.log("llamando"))
       .catch(err => console.log('Error launching dialer', err));
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  async EliminarCliente(cliente)
  {

    const alert = await this.alertController.create({
      header: 'Eliminar cliente',
      message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Estas seguro que deseas eliminar a ' + cliente.cliente.nombre1 + '</p>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //no desea eliminar
            this.popCtrl.dismiss();
          }
        }, {
          text: 'Eliminar',
          handler: () => {
              //si desea anular factura
              this.clienteService.deleteCliente(cliente);
              this.popCtrl.dismiss();
              this.presentToast('Eliminaste al cliente');
          }
        }
      ]
    });

    await alert.present();

  }

  editarCliente(personaId)
  {

    this.popCtrl.dismiss();
    this.router.navigate(['/editar-cliente/' + personaId]);
  }

  irFacturador(personaId)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/facturador/' + personaId]);
  }

}
