export class EpayResponse {

    constructor(
        public auditNumber: string,
        public referenceNumber: string,
        public authorizationNumber: string,
        public responseCode: string,
        public messageType: string,
        public responseMessage: string
      

    ) {}

}
