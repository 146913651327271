export class DatosGenerales {
    public Tipo: string;
    public ExpSpecified: boolean;
    public FechaHoraEmision: string;
    public CodigoMoneda: string;

    constructor(){
        this.Tipo = "FACT";
        this.ExpSpecified = false;
        this.FechaHoraEmision = new Date().toISOString().replace('Z', '');
        this.CodigoMoneda = "GTQ";
    }
}
