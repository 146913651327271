import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-headerback',
  templateUrl: './headerback.component.html',
  styleUrls: ['./headerback.component.scss'],
})
export class HeaderbackComponent implements OnInit {

  @Input() titulo: string;


  constructor() { }

  ngOnInit() {}

}
