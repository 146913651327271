import { Cantidad } from './cantidad';
import { Impuesto } from './impuesto';

export class Item {

    public UnidadMedida: string;
    public Descripcion: string;
    public DescripcionFront: string;
    public Nombre: string;
    public PrecioUnitario: number;
    public Precio: number;
    public Descuento: number;
    public Total: number;
    public NumeroLinea: number;
    public BienOServicio: string;

    public Impuestos= [];

    public Cantidad: Cantidad;

    constructor(
                nombre: string,
                descripcion: string,
                preciounitario: number,
                cantidad: number,
                descuento: number,
                bos: string,
                unidad: string,
                ){
        this.Nombre = nombre;
        this.DescripcionFront = descripcion;
        this.Descripcion = nombre + ' '  + descripcion;
        this.BienOServicio = bos;
        this.UnidadMedida = unidad;

        this.Cantidad = new Cantidad(cantidad);
        let pu = parseFloat(( preciounitario ).toFixed(6));        

        let precio = parseFloat(( pu * cantidad ).toFixed(6));

        this.Descuento = 0;
        if(descuento>0){
            let desc = parseFloat(( ( descuento / 100 )).toFixed(6));
            let pfinal = parseFloat(( ( pu * desc ) ).toFixed(6));
            //let aux = parseFloat(( ( pfinal * cantidad ) ).toFixed(4));
            this.Descuento = parseFloat(( ( pfinal * cantidad ) ).toFixed(6));
            //this.Descuento = parseFloat(( (precio - aux )  ).toFixed(4));    
        }

        let montogravable = parseFloat(( ( ( precio - this.Descuento ) / 1.12 ) ).toFixed(6));
        let valorimpuesto = parseFloat((( montogravable * 0.12 ) ).toFixed(6));
        
        this.Precio = precio;
        this.PrecioUnitario = pu;
        this.Total = parseFloat((montogravable + valorimpuesto).toFixed(6));
        
        this.Impuestos.push(new Impuesto(montogravable,valorimpuesto));

    }

}
