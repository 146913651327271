import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
    
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'usuarios',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'perfil-usuario',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-usuario/perfil-usuario.module').then( m => m.PerfilUsuarioPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'pago/:paquete/:tiempo',
    //  canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pago/pago.module').then( m => m.PagoPageModule)
  },
  {
    path: 'pagos-usuario/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pagos-usuario/pagos-usuario.module').then( m => m.PagosUsuarioPageModule)
  },
  {
    path: 'perfil-usuario-tabs',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-usuario-tabs/perfil-usuario-tabs.module').then( m => m.PerfilUsuarioTabsPageModule)
  },
  {
    path: 'cliente',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'listado-facturas/:tipoFiltro',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/listado-facturas/listado-facturas.module').then( m => m.ListadoFacturasPageModule)
  },
  {
    path: 'detalle-listado-facturas/:idfactura',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/detalle-listado-facturas/detalle-listado-facturas.module').then( m => m.DetalleListadoFacturasPageModule)
  },
  {
    path: 'registro-cliente',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/registro-cliente/registro-cliente.module').then( m => m.RegistroClientePageModule)
  },
  {
    path: 'facturador/:idcliente',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/facturador/facturador.module').then( m => m.FacturadorPageModule)
  },
  {
    path: 'resumen-facturacion',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/resumen-facturacion/resumen-facturacion.module').then( m => m.ResumenFacturacionPageModule)
  },
  {
    path: 'modal-facturador',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/modal-facturador/modal-facturador.module').then( m => m.ModalFacturadorPageModule)
  },
  {
    path: 'factura-procesada/:idfactura',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/factura-procesada/factura-procesada.module').then( m => m.FacturaProcesadaPageModule)
  },
  {
    path: 'ayuda',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ayuda/ayuda.module').then( m => m.AyudaPageModule)
  },
  {
    path: 'registro-exito',
    loadChildren: () => import('./pages/registro-exito/registro-exito.module').then( m => m.RegistroExitoPageModule)
  },
  {
    path: 'pago-exito',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pago-exito/pago-exito.module').then( m => m.PagoExitoPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'editar-cliente/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/editar-cliente/editar-cliente.module').then( m => m.EditarClientePageModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./pages/recuperar-contrasenia/recuperar-contrasenia.module').then( m => m.RecuperarContraseniaPageModule)
  },
  {
    path: 'nueva-contrasenia/:token',
    loadChildren: () => import('./pages/nueva-contrasenia/nueva-contrasenia.module').then( m => m.NuevaContraseniaPageModule)
  },
  {
    path: 'perfil-tributario/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-tributario/perfil-tributario.module').then( m => m.PerfilTributarioPageModule)
  },
  {
    path: 'ventas',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/ventas/ventas.module').then( m => m.VentasPageModule)
  },
  {
    path: 'frases/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/frases/frases.module').then( m => m.FrasesPageModule)
  },
  {
    path: 'establecimientos/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/establecimientos/establecimientos.module').then( m => m.EstablecimientosPageModule)
  },
  {
    path: 'agregar-frase/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/agregar-frase/agregar-frase.module').then( m => m.AgregarFrasePageModule)
  },
  {
    path: 'agregar-establecimiento/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/agregar-establecimiento/agregar-establecimiento.module').then( m => m.AgregarEstablecimientoPageModule)
  },
  {
    path: 'perfil-admin/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-admin/perfil-admin.module').then( m => m.PerfilAdminPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'link-pago',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/link-pago/link-pago.module').then( m => m.LinkPagoPageModule)
  },
  {
    path: 'pos-movil',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pos-movil/pos-movil.module').then( m => m.PosMovilPageModule)
  },
  {
    path: 'nuevo-link',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/nuevo-link/nuevo-link.module').then( m => m.NuevoLinkPageModule)
  },
  {
    path: 'link-exito',
    loadChildren: () => import('./pages/link-exito/link-exito.module').then( m => m.LinkExitoPageModule)
  },
  {
    path: 'link/:linkCifrado',
    loadChildren: () => import('./pages/link/link.module').then( m => m.LinkPageModule)
  },
  {
    path: 'editar-link/:linkCifrado',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/editar-link/editar-link.module').then( m => m.EditarLinkPageModule)
  },
  {
    path: 'listado-transacciones/:tipoFiltro',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/listado-transacciones/listado-transacciones.module').then( m => m.ListadoTransaccionesPageModule)
  },
  {
    path: 'resumen-transacciones',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/resumen-transacciones/resumen-transacciones.module').then( m => m.ResumenTransaccionesPageModule)
  },
  {
    path: 'perfil-pago/:personaId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/perfil-pago/perfil-pago.module').then( m => m.PerfilPagoPageModule)
  },
  {
    path: 'pago-exito-public',
    loadChildren: () => import('./pages/pago-exito-public/pago-exito-public.module').then( m => m.PagoExitoPublicPageModule)
  },
  {
    path: 'paso2-paquetes',
    // canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/paso2-paquetes/paso2-paquetes.module').then( m => m.Paso2PaquetesPageModule)
  },
  {
    path: 'pos',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pos/pos.module').then( m => m.PosPageModule)
  },
  {
    path: 'home-principal',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/home-principal/home-principal.module').then( m => m.HomePrincipalPageModule)
  },
  {
    path: 'usuarios-partner',
    loadChildren: () => import('./pages/usuarios-partner/usuarios-partner.module').then( m => m.UsuariosPartnerPageModule)
  },
  {
    path: 'ventas-partner',
    loadChildren: () => import('./pages/ventas-partner/ventas-partner.module').then( m => m.VentasPartnerPageModule)
  },
  {
    path: 'punto-venta',
    loadChildren: () => import('./pages/punto-venta/punto-venta.module').then( m => m.PuntoVentaPageModule)
  },
  {
    path: 'ayuda-pay',
    loadChildren: () => import('./pages/ayuda-pay/ayuda-pay.module').then( m => m.AyudaPayPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'recurrencia-exito',
    loadChildren: () => import('./pages/recurrencia-exito/recurrencia-exito.module').then( m => m.RecurrenciaExitoPageModule)
  },  {
    path: 'tokens',
    loadChildren: () => import('./pages/tokens/tokens.module').then( m => m.TokensPageModule)
  },
  {
    path: 'pago-exito-cyber',
    loadChildren: () => import('./pages/pago-exito-cyber/pago-exito-cyber.module').then( m => m.PagoExitoCyberPageModule)
  },
  {
    path: 'nfc-prueba',
    loadChildren: () => import('./pages/nfc-prueba/nfc-prueba.module').then( m => m.NfcPruebaPageModule)
  },





















 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
