import { Component, OnInit } from '@angular/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { PopoverController, ToastController, ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-pop-opciones-usuarios',
  templateUrl: './pop-opciones-usuarios.component.html',
  styleUrls: ['./pop-opciones-usuarios.component.scss'],
})
export class PopOpcionesUsuariosComponent implements OnInit {
 /* Variables para verificacion de plataforma nativa */
  isNative : Boolean;
  objeto : any;
  constructor(private callNumber: CallNumber,
              private popCtrl: PopoverController,
              private toastCtrl: ToastController,
              private router: Router,
              public actionSheetController: ActionSheetController,
              private usuarioService: UsuarioService) { }

  ngOnInit() {
    /* Verifica si la plataforma es nativa */
    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
    //console.log('isNative : ',this.isNative);
  }

  llamar(numero)
  {
    this.popCtrl.dismiss();
     this.callNumber.callNumber(numero, true)
       .then(res => console.log("llamando"))
       .catch(err => console.log('Error launching dialer', err));
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  cambiarEstado(usuario)
  {

    this.popCtrl.dismiss();
    this.presentActionSheet(usuario);
  }

  perfilTributario(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/perfil-tributario/' + usuario.persona.personaId]);
  }

  perfilPago(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/perfil-pago/' + usuario.persona.personaId]);
  }

  perfil(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/perfil-admin/' + usuario.persona.personaId]);
  }

  frases(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/frases/' + usuario.persona.personaId]);
  }

  agregarfrases(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/agregar-frase/' + usuario.persona.personaId]);
  }
  establecimientos(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/establecimientos/' + usuario.persona.personaId]);
  }

  agregarEstablecimientos(usuario)
  {
    this.popCtrl.dismiss();
    this.router.navigate(['/agregar-establecimiento/' + usuario.persona.personaId]);
  }

  async presentActionSheet(usuario) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Nuevo estado',
      cssClass: 'nuevosEstados',
      buttons: [{
        text: 'Agencia',
        icon: 'business-sharp',
        handler: () => {
          usuario.estado = 1;
          this.editarUsuario(usuario);
        }
      }, {
        text: 'Firmas',
        icon: 'newspaper-sharp',
        handler: () => {
          usuario.estado = 2;
          this.editarUsuario(usuario);
        }
      }, {
        text: 'Activos',
        icon: 'person-sharp',
        handler: () => {
          usuario.estado = 3;
          this.editarUsuario(usuario);
        }
      }, 
      {
        text: 'Inactivos',
        icon: 'person-outline',
        handler: () => {
          usuario.estado = 4;
          this.editarUsuario(usuario);
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }
    
      editarUsuario(usuario){
        //console.log(usuario);
        this.usuarioService.putUsuario(usuario);
        this.presentToast('Has actualizado el estado del usuario');
      }
    
  cambiarRol(usuario){            /* Metodo para cambiar Rol */
    this.popCtrl.dismiss();
    //console.log(usuario);
    this.presentActionSheetChageRol(usuario);
  }


  async presentActionSheetChageRol(usuario) { /*Metodo para mostrar las opciones de cambio de Rol */
    const actionSheet = await this.actionSheetController.create({
      header: 'Cambiar Rol',
      cssClass: 'nuevosEstados',
      buttons: [{
        text: 'Administrador',
        icon: 'person-circle-outline',
        handler: () => {
          //console.log('Cambiar rol: Administrado');
          if(usuario.rol!=0){
            usuario.rol = 0;
            this.editarUsuario(usuario);
          }else{
            this.presentToast('El rol seleccionado es el rol actual');
          }

        }
      }, {
        text: 'Cliente',
        icon: 'person-outline',
        handler: () => {

          //console.log('Cambiar rol: Cliente');
          if(usuario.rol!=1){
            usuario.rol = 1;
            this.editarUsuario(usuario);
          }else{
            this.presentToast('El rol seleccionado es el rol actual');
          }
          
        }
      }, {
        text: 'Cliente Premium',
        icon: 'person-add-outline',
        handler: () => {
          //console.log('Cambiar rol: Cliente Premium');
          if(usuario.rol!=2){
            usuario.rol = 2;
            this.editarUsuario(usuario);
          }else{
            this.presentToast('El rol seleccionado es el rol actual');
          }
          

        }
      },
      
      {
        text: 'Partner',
        icon: 'thumbs-up-outline',
        handler: () => {

          if(usuario.rol!=3){
            usuario.rol = 3;
            this.editarUsuario(usuario);
          }else{
            this.presentToast('El rol seleccionado es el rol actual');
          }
          

        }
      },
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          //console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

     

}
