import { Direccion } from './direccion';

export class Emisor {
    public NITEmisor: string;
    public NombreEmisor: string;
    public CodigoEstablecimiento: string;
    public NombreComercial: string;
    public CorreoEmisor: string;
    public AfiliacionIVA: string;

    public DireccionEmisor: Direccion;
    // nit: string, nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string
    constructor(nit: string, nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string){
        // this.NITEmisor= "100888704",
		// this.NombreEmisor= "DIGITAL RISING, SOCIEDAD ANONIMA";
		// this.CodigoEstablecimiento= "1";
		// this.NombreComercial= "DIGITAL RISING";
		// this.CorreoEmisor= "josue@digital.com";
        // this.AfiliacionIVA= "GEN";
        this.NITEmisor= nit,
		this.NombreEmisor= nombreEmisor;
		this.CodigoEstablecimiento= codigoEstablecimiento;
		this.NombreComercial= nombreComercial;
		this.CorreoEmisor= correoEmisor;
	    this.AfiliacionIVA= afiliacionIVA;
        this.DireccionEmisor = new Direccion("Zona 11");
    }
}
