import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Clientes } from '../models/clientes.model';


@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  baseUrl = 'https://api.swigit.gt/';
  constructor(private http: HttpClient) { }

  private _clientes = new BehaviorSubject<Clientes[]>([]);


  getClientes(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.get(`${this.baseUrl}Cliente/Usuario/${idusuario}`, { headers: headers });
     
  }

  getCliente(idcliente)
  {
    //console.log(idcliente);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get(`${this.baseUrl}Cliente/${idcliente}`, { headers: headers });
     
  }

  postCliente(cliente)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Cliente/CrearCliente', cliente, { headers: headers });
    
  }

  putCliente(cliente)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.put('https://api.swigit.gt/Cliente/ModificarCliente', cliente, { headers: headers }).subscribe();
    
  }

  deleteCliente(cliente)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Cliente/EliminarCliente', cliente, { headers: headers }).subscribe();


  }


}
