import { Component, OnInit, Input } from '@angular/core';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { FELObj } from '../../models/fel/felobj';
import { resultado_certificacion } from '../../models/fel/resultado_certificacion';
import { File } from '@ionic-native/file/ngx';

@Component({
  selector: 'app-pop-facturador',
  templateUrl: './pop-facturador.component.html',
  styleUrls: ['./pop-facturador.component.scss'],
})
export class PopFacturadorComponent implements OnInit {

  @Input() resultado: resultado_certificacion;
  FechaResumen: Date;
  isNative: Boolean;

  constructor(private printer: Printer,
    private file: File,
    private nativeHTTP: HTTP,
    private ft: FileTransfer) {   }

  ngOnInit() {
    //console.log("pop facturador")
    this.FechaResumen = new Date(this.resultado.fecha);

    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
    //console.log(this.resultado);
    //console.log("endpop facturador")
  }

  ionViewDidEnter(){
    console.log(this.resultado.fecha);
    this.FechaResumen = new Date(this.resultado.fecha);

  }

  descargar()
  {
    let downloadUrl = "";
   
    if(localStorage.getItem("token") == "9719707487b6eab34f7fb62255c46a17" || localStorage.getItem("token") == "ada7f02880481b181c6335fe3fcbeee1")
       downloadUrl = "https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=" + this.resultado.uuid;
    else
       downloadUrl = 'https://files.swigit.gt/' + localStorage.getItem("nit_emisor") + '/' + this.resultado.uuid + '.pdf';

    window.open(downloadUrl);
  }

  imprimir()
  {
     let downloadUrl = 'https://files.swigit.gt/' + localStorage.getItem("nit_emisor") + '/ticket-' +  this.resultado.uuid + '.pdf';
   //  let downloadUrl = 'https://files.swigit.gt/' + "11400049K" + "/" + this.resultado.uuid + '.pdf';
     let path = this.file.dataDirectory;
     // window.open(downloadUrl);
    console.warn("empezo descarga");
     const transfer = this.ft.create(); //download the file
     transfer.download(downloadUrl, `${path}` + this.resultado.uuid + '.pdf')
     .then(entry => {
      console.warn("llego descarga");
       let url = entry.toURL();
       console.warn("URL: " + url);
      //  this.fileOpener.open(url, 'application/pdf');
      this.printPDF(url);
     }
     ,
     error=>
     {
      console.warn("ERROR EN DESCARGA: " + error);
     });


  }

  printPDF(URLticket)
  {
    let options: PrintOptions = {
      name: 'MyDocument',
      duplex: false,
      orientation: 'portrait',
      monochrome: true
    }

    this.printer.isAvailable().then(
      data =>
      {
        console.log("IMPRESORA HABILITADA: " + data);
        this.printer.print(URLticket, options).then(
          exito=>
          {
            console.log("IMPRIMIO CON EXITO: " + exito);
          },
          error=>
           {
            console.log("ERROR AL IMPRIMIR: " + error);
           });
      },
      error =>
      {
        console.log("ERROR IMPRESORA NO HABILITADA: " + error);
      } );
  }

  

}
