import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.scss'],
})
export class HeadermenuComponent implements OnInit {

  @Input() titulo: string;

  constructor() { }

  ngOnInit() {}

}
