import { Component, OnInit } from '@angular/core';
import { ToastController, PopoverController, AlertController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/services/cliente.service';
import { Link } from 'src/app/models/link';
import { EpayService } from 'src/app/services/epay.service';

@Component({
  selector: 'app-opciones-link',
  templateUrl: './opciones-link.component.html',
  styleUrls: ['./opciones-link.component.scss'],
})
export class OpcionesLinkComponent implements OnInit {

/*  Validacion de plataforma nativa  */
isNative: Boolean;
objeto: any;
linkIdCifrado = "812234dsdasdsad";
baseUrl = "https://swigit.gt/link/";

constructor(private callNumber: CallNumber,
  private popCtrl: PopoverController,
  private toastCtrl: ToastController,
  private alertController: AlertController,
  private clienteService: ClienteService,
  private router: Router,
  private epayService: EpayService) { }

ngOnInit() {
  /* Verifica si la plataforma de nativa  */
  if(localStorage.getItem("isNative")=="false"){
    this.isNative=false;
  }else{
    this.isNative=true;
  }
  //console.log('isNative : ',this.isNative);
}



async presentToast(mensaje: string) {
  const toast = await this.toastCtrl.create({
    message: mensaje,
    duration: 2000
  });
  toast.present();
}

async Eliminar(objeto: Link)
{

  const alert = await this.alertController.create({
    header: 'Eliminar link',
    message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Estás seguro que deseas eliminar el link ' + objeto.nombre + '?</p>',
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          //no desea eliminar
          this.popCtrl.dismiss();
        }
      }, {
        text: 'Eliminar',
        handler: () => {
            //si desea eliminar
            this.epayService.deleteLink(objeto).subscribe
            (
              data =>
              {
                console.log(data);
                this.popCtrl.dismiss(true);
                this.presentToast('Eliminaste el link de pago');
              },
              error => {
                console.log(error);
                this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
              }
            );
       
        }
      }
    ]
  });

  await alert.present();

}

editar(objeto: Link)
{

  this.popCtrl.dismiss();
  this.router.navigate(['/editar-link/' + objeto.linkPagoToken]);
}

irLink(objeto: Link)
{
  this.popCtrl.dismiss();
  console.log(objeto);
  this.router.navigate(['/link/' + objeto.linkPagoToken]);
}

copiarLink(objeto: Link)
{
  this.copyMessage(this.baseUrl + objeto.linkPagoToken);
  this.popCtrl.dismiss();
  this.presentToast('¡Excelente! Puedes compartir el link a tu cliente. ');
}

copyMessage(val: string){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}

}
