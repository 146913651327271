import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { NombreNitRespuesta } from '../models/nombre-nit-respuesta';


@Injectable({
  providedIn: 'root'
})
export class FelService {

  constructor(private http: HttpClient) { }

  getFacturas(filtroFecha) : Observable<any>
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.post('https://api.swigit.gt/Fel/ObtenerFacturas', filtroFecha, { headers: headers });
     
  }

  getResumenFacturacion(personaId)
  {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get(`https://api.swigit.gt/Fel/ResumenFacturacion/${personaId}`, { headers: headers });
     
  }

  facturar(doc)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Fel/Certificar', 
                  doc, 
                  { headers: headers });
                  // .subscribe(data => {
                  //   console.log("RESPUESTA #1");
                  //   console.log(data);
                  //   console.log("RESPUESTA #2");
                  //   console.log(data['_body']);
                  //   console.log("RESPUESTAS END");
                  // }, error => {
                  //   console.log(error);
                  // });
  }

  anular(doc)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Fel/Anular', 
                  doc, 
                  { headers: headers });
                  // .subscribe(data => {
                  //   console.log("RESPUESTA #1");
                  //   console.log(data);
                  //   console.log("RESPUESTA #2");
                  //   console.log(data['_body']);
                  //   console.log("RESPUESTAS END");
                  // }, error => {
                  //   console.log(error);
                  // });
  }

  reporteContabilidad(filtroFecha)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });

      var url = "";
      if(localStorage.getItem("token") == "ada7f02880481b181c6335fe3fcbeee1" || localStorage.getItem("token") == "7d61fe80096fe9a74b9a240689f002ff")
        url = "https://api.swigit.gt/Fel/v2/Contabilidad";
      else
        url = "https://api.swigit.gt/Fel/Contabilidad";

     return this.http.post(url, filtroFecha, { headers: headers });
     
  }

  getNombreNIT(objNIT)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.post<NombreNitRespuesta>('https://epayapi.swigit.gt/nit', objNIT, { headers: headers });
  }

}
