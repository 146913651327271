import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Item } from '../models/fel/item';

@Injectable({
  providedIn: 'root'
})
export class WordPressService {


  urlPrincipal = "https://agdesign.com.gt/wp-json/wc/v2";
  llaves = "?consumer_key=ck_2c1391e03f828ab74df3d68ff04e169dfbb32d98&consumer_secret=cs_585012f85ef10df5077c3ca7b8a68dc18a63c3e3";
  //URL FUNCIONANDO: https://agdesign.com.gt/wp-json/wc/v2/products?consumer_key=ck_2c1391e03f828ab74df3d68ff04e169dfbb32d98&consumer_secret=cs_585012f85ef10df5077c3ca7b8a68dc18a63c3e3
  allPosts = null;
  pages: any;

  constructor(private http: HttpClient) { }


  getProductosWC(url, consumer_key, consumer_secret, search?, page?, category?)
  {
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json'
    //   });

    let pagina = page == null ? 1 : page;


    let options = { 
      observe: "response" as 'body',
      params: {
        per_page: '12',
        page: ''+ pagina
      }
    };

    let urlDinamica = "";
    console.log("search: ");
    console.log(search);
    console.log("CATEGORIA: " + category);
    if(search == null || search == "")
      urlDinamica = "https://" + url + "/wp-json/wc/v2" + "/products" + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret;
    else
      urlDinamica = "https://" + url + "/wp-json/wc/v2" + "/products" + "?search=" + search + "&consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret;

    if(category != null && category != "" && category != 0)
      urlDinamica = urlDinamica + "&category=" + category;
    // return this.http.get(this.urlPrincipal + "/products" + this.llaves, {headers: headers});
    console.log("URL DINAMICA: " + urlDinamica);
    return this.http.get<any[]>(urlDinamica, options) .pipe(
      map(res => {
        this.pages = res['headers'].get('x-wp-totalpages');
        this.allPosts = res['headers'].get('x-wp-total');
        return res['body'];
      })
    );
  }

  

  getCategoriasProducto(url, consumer_key, consumer_secret)
  {
    
    let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
    });

  let urlDinamica = "https://" + url + "/wp-json/wc/v2" + "/products/categories" + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret;

  // return this.http.get(this.urlPrincipal + "/products" + this.llaves, {headers: headers});
  return this.http.get<[]>(urlDinamica, {headers: headers});

  }

  getVariaciones(url, consumer_key, consumer_secret, productoId)
  {
    
    let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
    });

  let urlDinamica = "https://" + url + "/wp-json/wc/v2" + "/products/" + productoId + "/variations" + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret;

  // return this.http.get(this.urlPrincipal + "/products" + this.llaves, {headers: headers});
  return this.http.get<[]>(urlDinamica, {headers: headers});

  }

  putProductoWC(url,consumer_key, consumer_secret, producto, id)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });

    console.log("URL: " + "https://" + url + "/wp-json/wc/v2" + "/products/" + id + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret);
    return this.http.put("https://" + url + "/wp-json/wc/v2" + "/products/" + id + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret, producto, {headers: headers}).subscribe();
  }

  postOrdenWC(url, consumer_key, consumer_secret, orden)
  {
  //  console.log(usuario);
  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
    });


    let urlDinamica = "https://" + url + "/wp-json/wc/v2" + "/orders" + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret;

    // return this.http.post(this.urlPrincipal + "/orders"  + this.llaves, orden, { headers: headers });
    return this.http.post(urlDinamica, orden, { headers: headers });
  }


  
  putOrdenWC(url,consumer_key, consumer_secret, orden, id)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      });

    console.log("URL: " + "https://" + url + "/wp-json/wc/v2" + "/orders/" + id + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret);
    return this.http.put("https://" + url + "/wp-json/wc/v2" + "/orders/" + id + "?consumer_key=" + consumer_key + "&consumer_secret=" + consumer_secret, orden, {headers: headers}).subscribe();
  }



}
